import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import PageHeading from "../components/PageHeading"
import FeaturedContent from "../components/FeaturedContent"
import CarouselSlide from "../components/CarouselSlide"
import PromoVideo from "../components/PromoVideo"
import TestimonialsCarousel from "../components/TestimonialsCarousel"

import "../assets/sass/style.scss"

const Community = ({ props }) => {
  return (
    <Layout>
      <SEO title="GoodGym classes at Tower Hamlets" description="Laura is the Tower Hamlets lead for GoodGym - a charity that combines exercise and volunteering, focusing on group fitness and building ever-important community connections." />

      <PageHeading text="Community"></PageHeading>

      <FeaturedContent
        cssClass="featured-content pt-0 bg-grey"
        heading="GoodGym"
        imageSrc="Laura_GoodGym_TowerHamlets@2x.jpg"
        imageAlt="GoodGym Tower Hamlets"
        btnAction="/contact"
        btnLabel="Get in Touch"
      >
        <p>
          Laura is the Tower Hamlets lead for <em>GoodGym</em>, a community of runners who 
          combine exercise and volunteering. <a href="https://www.goodgym.org/v3/profile/laura-williams-6ffd542e-7c9e-4a4a-a5c7-1a560e611b0e" title="Visit GoodGym">You can read more about Laura’s role at <em>GoodGym</em> here</a>.
        </p>
      </FeaturedContent>

      <PromoVideo
        cssClass="promo-video bg-yellow"
        heading="Youth Sports Provision"
        videoSrcURL="https://player.vimeo.com/video/534469142?loop=1"
        videoCopyright="&copy; Trapped in Zone One"
        btnAction="/contact"
        btnLabel="Get in touch"
      >
        <p>
          Laura is interested in the role fitness plays in developing identity and ambition in young people from all walks of life. She has worked on projects such as London Youth’s <a href="https://londonartsandhealth.org.uk/event/trapped-in-zone-one-good-for-girls"><em>Good for Girls</em></a> programme with East London-based organisation, <em>Trapped in Zone One</em>.
        </p>
        <p>
          Laura is also the HAF (<em>Holiday Activities and Food Programme</em>) Youth Coordinator for the charity <em>StreetGames</em>, supporting the <em>London Borough of Tower Hamlets’</em> HAF team. Her role includes supporting providers, flagging delivery and safeguarding issues, and impact-measurement. <a href="https://www.streetgames.org/news-2/streetgames-haf-support-in-tower-hamlets-and-haringey" title="Visit StreetGames">Read more about Laura’s <em>StreetGames</em> role here</a>. 
        </p>
      </PromoVideo>

      <TestimonialsCarousel className="bg-red">
        <CarouselSlide author="Lyndsey Phillips, StreetGames' East London Doorstep Sport Advisor">
          <p>
            StreetGames, a national doorstep sport charity, approached Laura to
            join our ‘Fit and Fed’ team in Tower Hamlets last summer.
          </p>
          <p>
            This was an intensive programme of delivery trying to reach the
            communities that COVID had hit hardest – and we knew we needed a
            really proactive, energetic and resilient team of staff. We had seen
            Laura ‘in action’ previously and knew she had exactly the right
            skillset and enthusiasm to lead on this.
          </p>
          <p>
            Laura played a critical part in coordinating the delivery of
            high-quality sporting programmes and nutritious meals to thousands
            of young people across Tower Hamlets, and was a total joy to work
            with throughout.
          </p>
        </CarouselSlide>
        <CarouselSlide author="Ivo Gormley, Founder and CEO of GoodGym">
          <p>
            Laura has an extraordinary dedication to building community, and to
            the people around her.
          </p>
          <p>
            She is driven to make connections, develop relationships and improve
            the world around her, because that's who she is.
          </p>
          <p>
            Laura has built a strong community in Tower Hamlets, and gone above
            and beyond to create new connections and support the group.
          </p>
          <p>
            She's brimming with infectious enthusiasm which is matched only by
            her ability to get things done. It's been a huge privilege to work
            with her.
          </p>
        </CarouselSlide>
      </TestimonialsCarousel>
    </Layout>
  )
}

export default Community
